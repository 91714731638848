<template>
  <button v-if="text"
          :class="[{'modal-button-disabled': isDisabled}, buttonClass]"
          :disabled="isDisabled"
          class="button-main button-basic"
          @click="onButtonClick()"
  >
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <img v-if="icon" :src="icon" class="button-icon" alt="button icon"/>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'ModalButton',
  props: {
    onButtonClick: {
      type: Function,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    buttonClass: {
      type: String,
      required: false,
      default: ''
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.button-basic {
  @include hover-active-pointer();
  border: none;
  border-radius: rem(8);
  box-shadow: rem(4) rem(4) 0 0 rgba(0,0,0,0.1);
  font-size: rem(18);
  font-weight: 600;
  letter-spacing: 0;
  line-height: rem(22);
  height: rem(50);
  padding: 0;
  position: relative;
  width: 50%;
}

.button-icon {
  @include position(absolute, $top: 50%, $left: rem(22));
  transform: translateY(-50%);
}

.button-modal-right {
  background-color: var(--pink_main);
  color: white;
  margin-left: rem(15);
}

.button-modal-left {
  background-color: white;
  color: var(--blue_dark);
  margin-right: rem(15);
}

.single-button {
  width: 100%;
  margin: 0;
}

.button-red {
  background-color: var(--red_error);
}

@media screen and (min-width: $screen-tv) {
  .button-basic {
    border-radius: 50px;
    font-size: 0.74vw;
    height: 2.1vw;
    width: 46%;
  }

  .button-modal-left {
    background-color: white;
  }
}
</style>
