<template>
  <div v-if="isShown" class="basic-modal-wrapper">
    <div :style="[{'width': modalWidth}, {'height': modalHeight}]" class="basic-modal-content">
      <!-- Close button -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img v-if="hasCloseButton"
           src="../../../assets/icons/icn_arrow.svg"
           class="close-button"
           alt="close icon"
           @click="closeModal()">

      <!-- Optional image -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <img v-if="modalOptions.modalContent.imageTop"
           :src="modalOptions.modalContent.imageTop"
           class="image-top"
           alt="delete account icon"/>

      <!-- Text with slot-->
      <div class="basic-modal-text-wrapper">
        <h2 class="basic-modal-title">{{ modalOptions.modalContent.modalTitle }}</h2>
        <p class="basic-modal-subtext" v-html="modalOptions.modalContent.modalText"></p>
        <p v-if="modalOptions.modalContent.modalText2" class="basic-modal-subtext basic-modal-subtext-2">{{ modalOptions.modalContent.modalText2 }}</p>
        <slot name="content"/>
      </div>

      <!-- Buttons -->
      <ModalButtons :button-left-options="modalOptions.modalContent.buttonOptionsLeft"
                    :button-right-options="modalOptions.modalContent.buttonOptionsRight"
                    :on-button-left-click="closeModal"
                    :on-button-right-click="executeCallback"
                    :is-button-right-disabled="buttonRightDisabled"
                    :is-button-left-disabled="buttonLeftDisabled"
                    :only-cancel-button="onlyCancelButton"
                    :single-button="singleButton"/>
    </div>
  </div>
</template>

<script>
import { RESET_ACTIVE_BASIC_MODAL, RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_SHOWN } from '@/store/mutations'
import ModalButtons from '@/components/elements/basicModal/ModalButtons'
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  name: 'BasicModal',
  components: {
    ModalButtons,
  },
  props: {
    modalWidth: {
      type: String,
      required: false,
      default: '550px'
    },
    modalHeight: {
      type: String,
      required: false,
      default: 'auto'
    },
    buttonLeftDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonRightDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hasInputField: {
      type: Boolean,
      required: false,
      default: false
    },
    singleButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props) {
    const store = useStore()

    const isShown = computed(() => store.getters.getBasicModalShown)
    const modalOptions = computed(() => store.getters.getBasicModalOptions)



    function closeModal() {
      // If a cancel callback has been passed, we should call it.
      if (modalOptions.value.cancelCallback) {
        modalOptions.value.cancelCallback(modalOptions.value?.data)
      }

      // Reset the store values related to the BasicModal.
      store.commit(RESET_BASIC_MODAL_DATA)
      store.commit(RESET_ACTIVE_BASIC_MODAL)
      store.commit(SET_BASIC_MODAL_SHOWN, false)
    }

    function executeCallback() {
      modalOptions.value.executeCallback(modalOptions.value?.data)
    }

    return {
      isShown,
      modalOptions,
      closeModal,
      executeCallback
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.basic-modal-wrapper {
  @include position-equal(fixed, $value: 0, $elevation: 3000);
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  box-shadow: 0 rem(2) rem(8) rem(3) rgba(0, 0, 0, 0.2);
}

.basic-modal-content {
  @include position(absolute, $top: 50%, $left: 50%);
  background-color: var(--gray_light);
  border-radius: rem(12);
  box-shadow: 0 rem(4) rem(20) rem(-2) rgba(24, 29, 41, 0.2);
  padding: rem(40) rem(56) rem(40) rem(56);
  text-align: center;
  transform: translate(-50%, -50%);
}

.close-button {
  @include position(absolute, $top: rem(21), $right: rem(25));
  width: rem(23);

  &:hover {
    cursor: pointer;
  }
}

.basic-modal-text-wrapper {
  margin-bottom: rem(27);
}

.basic-modal-title {
  font-size: rem(24);
  line-height: rem(28);
  margin-bottom: rem(20);
}

.basic-modal-subtext {
  font-size: rem(16);
  line-height: rem(22);
}

.basic-modal-subtext-2 {
  margin-top: rem(25);
}

.image-top {
  display: none;
}

@media screen and (min-width: $screen-lg) {
  .image-top {
    display: inline;
    margin-bottom: 25px;
    width: 130px;
  }

  .basic-modal-content {
    @include position(absolute, $top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
  }

  .basic-modal-text-wrapper {
    margin-bottom: 35px;
  }
}

// TODO: If 4K support is needed, feel free to add the CSS.
// @media screen and (min-width: $screen-tv) {}
</style>
