export default class BasicModalContentItem {
  constructor(modalTitle, modalText, modalText2 = '', imageTop, buttonTextLeft, buttonClassLeft, buttonIconLeft, buttonTextRight,
    buttonClassRight, buttonIconRight) {
    this.modalTitle = modalTitle
    this.modalText = modalText
    this.modalText2 = modalText2
    this.imageTop = imageTop

    // Left button
    this.buttonTextLeft = buttonTextLeft
    this.buttonClassLeft = buttonClassLeft
    this.buttonIconLeft = buttonIconLeft

    // Right button
    this.buttonTextRight = buttonTextRight
    this.buttonClassRight = buttonClassRight
    this.buttonIconRight = buttonIconRight
  }

  /**
   * Gets the options for the left button of the modal.
   * @return {{icon: String, text: String, class: String}}
   */
  get buttonOptionsLeft() {
    return {
      text: this.buttonTextLeft,
      class: this.buttonClassLeft,
      icon: this.buttonIconLeft,
    }
  }

  /**
   * Gets the options for the right button of the modal.
   * @return {{icon: String, text: String, class: String}}
   */
  get buttonOptionsRight() {
    return {
      text: this.buttonTextRight,
      class: this.buttonClassRight,
      icon: this.buttonIconRight,
    }
  }

  static create(data) {
    return new BasicModalContentItem(data.modalTitle, data.modalText, data.modalText2, data.imageTop, data.buttonTextLeft,
      data.buttonClassLeft, data.buttonIconLeft, data.buttonTextRight, data.buttonClassRight, data.buttonIconRight)
  }
}
