import BasicModalContentItem from '@/models/basicModal/BasicModalContentItem'

export const BASIC_MODAL_CONTENT = {

  // Example
  BASIC_EXAMPLE_2: BasicModalContentItem.create({
    modalTitle: 'Example modal title',
    modalText: 'Example modal text.',
    buttonTextLeft: 'Cancel',
    buttonTextRight: 'Confirm',
    // buttonClassLeft
    // buttonClassRight
  }),

  /**
   * CMS PORTAL
   */
  // REGISTER
  CREATEDIA: BasicModalContentItem.create({
    modalTitle: 'Diatype',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Uploaden'
  }),

  EDIT_TRICK_WORDS: BasicModalContentItem.create({
    modalTitle: 'Voeg trucwoorden toe',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Toevoegen'
  }),

  /**
   * TEACHER PORTAL
   */
  // REGISTER
  WARNING_EMAILS: BasicModalContentItem.create({
    modalTitle: 'Waarschuwing',
    modalText: 'Weet je zeker dat je geen e-mails wilt ontvangen over MediaMasters? Deze e-mails bevatten belangrijke informatie over voorbereidingen, het spelverloop en downloadbare materialen zoals de poster en het certificaat van deelname.',
    modalText2: 'Je ontvangt alleen relevante informatie en ontvangt deze e-mails niet vaker dan noodzakelijk. Je kunt je keuze op een later moment nog wijzigen bij \'Mijn account\'.',
    buttonTextLeft: 'Pas voorkeur aan',
    buttonTextRight: 'Ga verder'
  }),

  // Warnings
  BROWSER_SUPPORT: BasicModalContentItem.create({
    modalTitle: 'De browser wordt niet ondersteund',
    modalText: 'Het spel kan niet gestart worden omdat je huidige browser niet word ondersteund. Probeer een van de onderstaande browsers om het spel te kunnen starten:',
    buttonTextRight: 'Terug',
    buttonClassRight: 'single-button'
  }),

  // MY SCHOOL
  REFUSE_REQUEST: (email) => BasicModalContentItem.create({
    modalTitle: 'Verzoek afwijzen',
    modalText: `Weet je zeker dat je het verzoek van <b><i>${email}</i></b> wilt afwijzen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijs af'
  }),
  ACCEPT_REQUEST: (email) => BasicModalContentItem.create({
    modalTitle: 'Verzoek accepteren',
    modalText: `Weet je zeker dat je het verzoek van <b><i>${email}</i></b> wilt accepteren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Accepteer'
  }),
  REMOVE_TEACHER: (email) => BasicModalContentItem.create({
    modalTitle: 'Collega verwijderen',
    modalText: `Weet je zeker dat je <b><i>${email}</i></b> verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen'
  }),
  SEND_NEW_REQUEST: BasicModalContentItem.create({
    modalTitle: 'Nieuwe uitnodiging sturen',
    modalText: 'Stuur een uitnodiging naar een collega door zijn/haar e-mailadres op te geven.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Stuur uitnodiging'
  }),

  // MY CLASS
  REMOVE_CLASS: (aClass) => BasicModalContentItem.create({
    modalTitle: 'Klas verwijderen',
    modalText: `Weet je zeker dat je de klas <b><i>${aClass}</i></b> wilt verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijder klas'
  }),
  EDIT_CLASS_CONFIRM: BasicModalContentItem.create({
    modalTitle: 'Wijzigingen opslaan',
    modalText: 'Weet je zeker dat je de gemaakte wijzigingen voor deze klas wilt opslaan?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Sla wijzigingen op'
  }),
  CREATE_SPECIAL_CLASS: BasicModalContentItem.create({
    modalTitle: 'Klas aanmaken',
    modalText: 'Je klas is geplaatst in MediaMasters voor groep 5/6. Dit houdt in:<br><li>Eenvoudiger taalgebruik</li><li>Filmische verhaallijn is afgestemd op 8-10 jarigen</li><li>Minder (lange) tekstbronnen</li><li>Makkelijkere vragen</li><li>Vereenvoudigde en korte opdrachten</li><li>Onderwerpen die passen bij de belevingswereld van 8-10 jarigen</li><br>Denk je dat dit niet klopt, neem dan contact op met de helpdesk via <span class="mail">info@mediamasters.nl</span>.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Klas aanmaken'
  }),
  CREATE_SPECIAL_CLASS_2: BasicModalContentItem.create({
    modalTitle: 'Klas aanmaken',
    modalText: '<span class="align-center">Je klas is geplaatst in MediaMasters voor groep 7/8. Denk je dat dit niet klopt, neem dan contact op met de helpdesk via <span class="mail">info@mediamasters.nl</span>.</span>',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Klas aanmaken'
  }),

  // SETTINGS
  DELETE_ACCOUNT: BasicModalContentItem.create({
    modalTitle: 'Account verwijderen',
    modalText: 'Weet je zeker dat je het account wilt verwijderen? Alle informatie op het account zal permanent worden verwijderd. Voer je wachtwoord in om door te gaan.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen',
    buttonClassRight: 'button-red'
  }),
  CHANGE_MEDIA_MASTERS_NEWSLETTER_PREFERENCE: BasicModalContentItem.create({
    modalTitle: 'Afmelden instructiemails',
    modalText: 'Weet je zeker dat je de instructiemails niet wilt ontvangen? De mails bevatten belangrijke informatie over het spelen van MediaMasters, zoals: de voorbereiding, uitleg over de landelijke wedstrijd en verschillende handige materialen zoals de handleiding en het certificaat.',
    modalText2: 'De instructiemails ontvang je niet vaker dan noodzakelijk. Je kunt je keuze altijd op een later moment weer wijzigen.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Pas voorkeur aan'
  }),
  MEDIA_WISE_NEWSLETTER: BasicModalContentItem.create({
    modalTitle: 'Afmelden nieuwsbrief',
    modalText: 'Weet je zeker dat je de de maandelijkse nieuwsbrief met meer informatie en tips over mediawijze thema’s niet meer wilt ontvangen?',
    modalText2: 'Je kunt je keuze altijd op een later moment weer wijzigen.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Pas voorkeur aan'
  }),

  CREATE_TAG: BasicModalContentItem.create({
    modalTitle: 'Tag toevoegen',
    modalText: '',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Toevoegen'
  }),

  DELETE_MISSION: (name) => BasicModalContentItem.create({
    modalTitle: 'Missie verwijderen',
    modalText: `Weet je zeker dat je de missie <b><i>${name}</i></b> wilt verwijderen? De missie en alle context worden verwijderd Deze actie kan niet ongedaan gemaakt worden.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Missie verwijderen'
  }),

  DELETE_SLIDE: (name) => BasicModalContentItem.create({
    modalTitle: 'Dia verwijderen',
    modalText: `Weet je zeker dat je de dia <b><i>${name}</i></b> wilt verwijderen?
    Deze actie kan niet ongedaan gemaakt worden.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Dia verwijderen'
  }),

  //MISSION VIDEOS
  DELETE_MISSION_VIDEO: (name) => BasicModalContentItem.create({
    modalTitle: 'Verwijder bestand',
    modalText: `Weet je zeker dat je <b><i>${name}</i></b> wilt verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen'
  }),

  DELETE_MISSION_VIDEO_FAILURE: (name) => BasicModalContentItem.create({
    modalTitle: 'Verwijderen mislukt',
    modalText: `De video <b><i>${name}</i></b> is nog in gebruik.`,
    buttonTextLeft: 'Ok',
    buttonTextRight: 'Ok',

  }),

  //TAGS
  DELETE_TAG: (name) => BasicModalContentItem.create({
    modalTitle: 'Tag verwijderen',
    modalText: `Weet je Zeker dat je de tag <b><i>${name}</i></b> wilt verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Tag verwijderen',
  }),

  //MEDIAQUIZ
  DELETE_MEDIAQUIZ: (name) => BasicModalContentItem.create({
    modalTitle: 'Vraag verwijderen',
    modalText: 'Weet je zeker dat je deze vraag wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Vraag verwijderen'
  }),

  //Instructions
  DELETE_INSTRUCTION_MODAL: (name) => BasicModalContentItem.create({
    modalTitle: 'Instructie verwijderen',
    modalText: `Weet je zeker dat je de instructie <b><i>${name}</i></b> wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen'
  }),

  DELETE_FILE: (name) => BasicModalContentItem.create({
    modalTitle: 'Verwijder bestand',
    modalText: `Weet je zeker dat je <b><i>${name}</i></b> wilt verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen'
  }),
  UPLOAD_FILE: BasicModalContentItem.create({
    modalTitle: 'Voeg Bijlage toe',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Toevoegen'
  }),
  UPLOAD_VIDEO: BasicModalContentItem.create({
    modalTitle: 'Voeg Video toe',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Toevoegen'
  }),

  //PRIZES
  DELETE_PRIZE: (name) => BasicModalContentItem.create({
    modalTitle: 'Prijs verwijderen',
    modalText: `Weet je zeker dat je de prijs <b><i>${name}</i></b> wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Prijs verwijderen'
  }),

  CANCEL_EARLY_PRIZE: BasicModalContentItem.create({
    modalTitle: 'Weet je zeker dat je de pagina wilt verlaten?',
    modalText: 'Je hebt niet-opgeslagen wijzigingen. Weet je zeker dat je deze pagina wilt verlaten? Niet-opgeslagen wijzigingen gaan verloren.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verlaten'
  }),


  //FAQ
  DELETE_FAQ: (name) => BasicModalContentItem.create({
    modalTitle: 'Vraag verwijderen',
    modalText: `Weet je zeker dat je vraag <b><i>${name}</i></b> wilt verwijderen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Vraag verwijderen'
  }),

  CANCEL_EARLY_FAQ: BasicModalContentItem.create({
    modalTitle: 'Weet je zeker dat je de pagina wilt verlaten?',
    modalText: 'Je hebt niet-opgeslagen wijzigingen. Weet je zeker dat je deze pagina wilt verlaten? Niet-opgeslagen wijzigingen gaan verloren.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verlaten'
  }),

  CANCEL_EARLY: BasicModalContentItem.create({
    modalTitle: 'Weet je zeker dat je de pagina wilt verlaten?',
    modalText: 'Je hebt niet-opgeslagen wijzigingen. Weet je zeker dat je deze pagina wilt verlaten? Niet-opgeslagen wijzigingen gaan verloren.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verlaten'
  }),

  // EXTRA_GAMES
  DELETE_EXTRA_GAME: (title) => BasicModalContentItem.create({
    modalTitle: 'Extra spel verwijderen',
    modalText: `Weet je zeker dat je de extra game <b><i>${title}</i></b> met alle context wit verwijderen? Deze actie kan niet ongedaan gemaakt worden.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijderen'
  }),

  // TODO: ADD the translations instead of the texts.
  LOGOUT: BasicModalContentItem.create({
    modalTitle: 'Uitloggen',
    modalText: 'Weet je zeker dat je wilt uitloggen?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Log uit',
  }),

  EXPORT_EMAILS: BasicModalContentItem.create({
    modalTitle: 'E-mailadressen exporten',
    modalText: 'Vanaf welke aanmaak datum moeten de e-mailadressen worden meegenomen in de export naar Excel?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Bevestig datum',
  }),

  /**
   * HELPDESK
   */
  DELETE_SCHOOL: (schoolName) => BasicModalContentItem.create({
    modalTitle: 'School verwijderen',
    modalText: `Weet je zeker dat je de school <b><i>${schoolName}</i></b> wilt verwijderen? `,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijder school',
  }),
  DELETE_SCHOOL_NOT_POSSIBLE: () => BasicModalContentItem.create({
    modalTitle: 'De school kan niet worden verwijderd',
    modalText: 'De school kan niet worden verwijderd omdat er nog leraren zijn gekoppeld. Verwijder eerst alle leraren om deze actie te kunnen uitvoeren.',
    buttonTextLeft: 'Terug',
    buttonTextRight: 'Terug',
  }),
  DELETE_CLASS: (className) => BasicModalContentItem.create({
    modalTitle: 'Klas verwijderen',
    modalText: `Weet je zeker dat je de klas <b><i>${className}</i></b> wilt verwijderen? `,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijder klas',
  }),
  DELETE_TEACHER: (teacherName) => BasicModalContentItem.create({
    modalTitle: 'Leraar verwijderen',
    modalText: `Weet je zeker dat je de leraar <b><i>${teacherName}</i></b> wilt verwijderen? `,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Verwijder leraar',
  }),

  APPROVE_TEACHER: (teacherName) => BasicModalContentItem.create({
    modalTitle: 'Verbinding goedkeuren',
    modalText: `Weet je zeker dat je link naar <b><i>${teacherName}</i></b> leraar wilt goedkeuren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Goedkeuren',
  }),

  DECLINE_TEACHER: (teacherName) => BasicModalContentItem.create({
    modalTitle: 'Verbinding afwijzen',
    modalText: `Weet je zeker dat je link naar <b><i>${teacherName}</i></b> leraar wilt weigeren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijs af',
  }),


  APPROVE_PROFESSIONAL: (name) => BasicModalContentItem.create({
    modalTitle: 'Verbinding goedkeuren',
    modalText: `Weet je zeker det je link naar <b><i>${name}</i></b> leraar wilt goedkeuren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Goedkeuren',
  }),

  // todo check Dutch weird text. It doesn't seem to cover what the modal does
  DECLINE_PROFESSIONAL: (name) => BasicModalContentItem.create({
    modalTitle: 'Verbinding afwijzen',
    modalText: `Weet je zeker det je link naar <b><i>${name}</i></b> leraar wilt weigeren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijs af',
  }),

  // todo check Dutch weird text. It doesn't seem to cover what the modal does
  DELETE_PROFESSIONAL: (name) => BasicModalContentItem.create({
    modalTitle: 'Verbinding afwijzen',
    modalText: `Weet je zeker det je link naar '${name}' leraar wilt weigeren?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijs af',
  }),
  // RICH TEXT EDITOR
  CREATE_NEW_LINK: BasicModalContentItem.create({
    modalTitle: 'Voeg een link toe',
    modalText: '',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Toevoegen'
  }),

  // RICH TEXT EDITOR
  UPDATE_LINK: BasicModalContentItem.create({
    modalTitle: 'Link bewerken',
    modalText: '',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Opslaan'
  }),

  PLAY_WITHOUT_CLASS: BasicModalContentItem.create({
    modalTitle: 'Spelen zonder klas',
    modalText: 'Je gaat nu spelen zonder klas. Je kan in deze modus geen bits verdienen. Wel kan je in deze modus zelf even de MediaMissie bekijken ter voorbereiding.',
    buttonTextLeft: 'Kies klas',
    buttonTextRight: 'Bevestig'
  }),
  SELECT_DIFFERENT_CLASS: (className) => BasicModalContentItem.create({
    modalTitle: 'Actieve klas',
    modalText: `Je gaat nu naar het overzicht van <b><i>${className}</i></b>. Weet je zeker dat je met deze klas wilt spelen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijzig klas'
  }),

  START_MISSION_WITH_CLASS: (className) => BasicModalContentItem.create({
    modalTitle: 'Missie starten',
    modalText: `Weet je zeker dat je met de klas <b><i>${className}</i></b> deze MediaMissie wilt spelen?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Speel met deze klas'
  }),
  VIEW_MISSIONS_WITH_CLASS: (className) => BasicModalContentItem.create({
    modalTitle: 'Missies bekijken',
    modalText: `Weet je zeker dat je met de klas <b><i>${className}</i></b> de MediaMissies wilt bekijken?`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Speel met deze klas'
  }),
  START_MISSION_WITHOUT_CLASS: BasicModalContentItem.create({
    modalTitle: 'Missie starten',
    modalText: 'Weet je zeker dat je deze MediaMissie wilt spelen zonder klas? Je verdient dan geen bits.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Speel zonder klas'
  }),
  VIEW_MISSIONS_WITHOUT_CLASS: BasicModalContentItem.create({
    modalTitle: 'Missies bekijken',
    modalText: 'Weet je zeker dat je de MediaMissies wilt bekijken zonder klas? Je verdient dan geen bits.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Speel zonder klas'
  }),

  RESET_MISSION: BasicModalContentItem.create({
    modalTitle: 'Missie resetten',
    modalText: 'Weet je zeker dat je de missie wilt resetten? De voortgang van de klas zal verloren gaan.',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Reset missie'
  }),

  EDIT_BITS_QUESTION: BasicModalContentItem.create({
    modalTitle: 'Wijzig aantal bits',
    modalText: 'Weet je zeker dat je het aantal verdiende bits voor deze vraag wilt wijzigen?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijzig aantal'
  }),
  EDIT_BITS_EXTRA_GAME: BasicModalContentItem.create({
    modalTitle: 'Wijzig aantal bits',
    modalText: 'Weet je zeker dat je het aantal verdiende bits voor deze extra game wilt wijzigen?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Wijzig aantal'
  }),

  BULK_ACCEPT_DECLINE: ({isApproving,classes,invalidClasses}) => BasicModalContentItem.create({
    modalTitle: `Goedkeuren ${isApproving ? 'accepteren' : 'weigeren'}?`,
    modalText: `Je staat op het punt om de volgende lessen te ${isApproving ? 'accepteren' : 'weigeren'}.`,
     modalText2: `Let op, ${invalidClasses} zijn niet geldig en worden overgeslagen omdat ze niet in afwachting van goedkeuring zijn.`,
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Goedkeuren'
  }),

  // DEMO MISSION WARNING
  DEMO_MISSION_WARNING: BasicModalContentItem.create({
    modalTitle: 'DemoMissie',
    modalText: 'De DemoMissie heeft als doel om MediaMasters uit te proberen en te oefenen. Jullie verdienen <b>geen bits</b> met de DemoMissie. Willen jullie doorgaan?',
    buttonTextLeft: 'Annuleren',
    buttonTextRight: 'Speel DemoMissie'
  }),

}
