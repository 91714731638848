<template>
  <div class="modal-button-wrapper" :class="{ 'single-button': singleButton || onlyCancelButton }">
    <!-- Button left -->
    <ModalButton v-if="!singleButton"
                 :on-button-click="onButtonLeftClick"
                 :is-disabled="isButtonLeftDisabled"
                 :button-class="`button-modal-left ${buttonLeftOptions.class}`"
                 :text="buttonLeftOptions.text"/>

    <!-- Button right -->
    <ModalButton v-if="!onlyCancelButton"
                 :on-button-click="onButtonRightClick"
                 :is-disabled="isButtonRightDisabled"
                 :button-class="`button-modal-right ${buttonRightOptions.class}`"
                 :text="buttonRightOptions.text"
                 :icon="buttonRightOptions.icon"/>
  </div>
</template>

<script>
import ModalButton from '@/components/elements/basicModal/ModalButton'

export default {
  name: 'ModalButtons',
  components: {
    ModalButton
  },
  props: {
    // Button left
    buttonLeftOptions: {
      type: Object,
      required: true,
    },
    onButtonLeftClick: {
      type: Function,
      required: true
    },
    isButtonLeftDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    // Button right
    buttonRightOptions: {
      type: Object,
      required: true,
    },
    onButtonRightClick: {
      type: Function,
      required: true
    },
    isButtonRightDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    singleButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.modal-button-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.modal-button-wrapper.single-button {
  justify-content: center;
}

.modal-button-disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}
</style>
